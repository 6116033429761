<template>
  <div class="websiteHead">
    <div class="home_container">
      <div class="header_top">
        <div class="head_top content">
          <div class="top_left">
            <span v-if="config.phone">{{ config.phone }}</span>
            <span v-if="config.email">{{ config.email }}</span>
          </div>
          <div class="top_right">
            <a href=""><img src="@/assets/images/f.png" alt="" srcset=""></a>
            <a href=""><img src="@/assets/images/bird.png" alt="" srcset=""></a>
            <a href=""><img src="@/assets/images/in.png" alt="" srcset=""></a>
            <a href=""><img src="@/assets/images/camera.png" alt="" srcset=""></a>
            <a href=""><img src="@/assets/images/triangle.png" alt="" srcset=""></a>
            <a href=""><img src="@/assets/images/headset.png" alt="" srcset=""></a>
          </div>

        </div>
      </div>
      <div class="container_head content">
        <div class="head_body" @click="$router.push('/')">
          <img src="@/assets/images/of.png" alt="">
        </div>
        <div class="head-search">
          <i class="el-icon-search" v-if="inputShow" @click="handleOpen"></i>
          <el-input size="mini" v-if="!inputShow" placeholder="Please enter what you want to search for" v-model="input3">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="handleClose"></i>
          </el-input>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <span class="text">{{ text }}</span> <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in drowList" :command='item.value' :key="item.value">{{
                item.label }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="home_navigation" :class="{ fixed: scoll > 17 }">
      <div class="navigation_content content">
        <div class="navigation_title" v-for="(item, index) in navList" :key="index"
          :class="{ active: currentId == index }" @click="handleClick(index, item.href)">
          {{ item.text }}
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: ['currentId'],
  data() {
    return {
      scoll:0,
      navList: [
        {
          text: "Crystal Manufacturer",
          href: '/factory'
        },
        {
          text: "OEM Service",
          href: '/oemservice'
        },
        {
          text: "Brand Principles",
          href: '/brand'
        },
        {
          text: "Where to Buy",
          href: '/home'
        },
        {
          text: "Reviews",
          href: '/review'
        },
        {
          text: "Amazing Collections",
          href: '/ecatalogue'
        },
      ],
      inputShow: true,
      text: "EN",
      drowList: [
        {
          value: "zh",
          label: 'ZH',
        },
        {
          value: "en",
          label: 'EN',
        },
      ],
      config:{}
    };
  },
  watch: {},
  computed: {},
  methods: {
    //获取屏幕滚动的高度
    scrollTop() {
      this.scoll = document.documentElement.scrollTop
    },
    //语言切换
    handleCommand(command) {
      this.text = command
      this.$i18n.locale = command
    },
    //点击搜索图标,弹出输入框
    handleOpen() {
      this.inputShow = false
    },
    //点击搜索框中的搜索图标,关闭搜索框
    handleClose() {
      this.inputShow = true
    },
    //路由跳转
    handleClick(e, to) {
      if(this.currentId == e){
        return
      }
      if (to) {
        this.$router.push(to)
      }
    },
    //获取系统配置
    getSysConfig(){
      this.api.user.sysConfig().then(res=>{
        this.config = res
      })
    }
  },
  created() { },
  mounted() {
    // window.addEventListener('scroll', this.scrollTop)
    this.getSysConfig()
  },
  beforeRouteLeave(to, from, next) {
    // window.removeEventListener('scroll', this.scrollTop)
    // next()
  },
};
</script>
<style lang="less" scoped>
.websiteHead {
  width: 100%;

  .home_container {
    background: url('@/assets/images/topbg.png') no-repeat;
    background-size: 100% 100%;

    .header_top {
      height: 5vh;
      // background: #EFEFEF;
      // opacity: 0.5;
      z-index: -999;

      .head_top {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;

        .top_left {
          font-size: 1.3vw;
          font-family: Arial;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 1vw;
          z-index: 999;

          span:nth-child(2) {
            margin-left: 20px;
          }
        }

        .top_right {
          a {
            img {
              width: 1.5vw;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .container_head {
      height: 12vh;

      .head_body {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2vh;

        img {
          width: 20%;
        }
      }

      .head-search {
        float: right;
        color: #FFF;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        i {
          font-size: .9vw;
          cursor: pointer;
        }

        .el-input {
          width: 60%;
        }

        .el-dropdown {
          margin-left: 15px;

          .el-dropdown-link {
            font-size: 1vw;
            font-family: Arial;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
          }
        }
      }
    }

  }

  .home_navigation {
    width: 100%;
    height: 5vh;
    background: #1B2E4E;

    .navigation_content {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .navigation_title {
        font-size: 1vw;
        font-family: Jost;
        font-weight: 400;
        color: #FFFFFF;
        height: 5vh;
        line-height: 5vh;
        padding: 0 10px;
        box-sizing: border-box;
      }

      .navigation_title:hover {
        cursor: pointer;
        line-height: 5vh;
        height: 100%;
        background: #C70000;
      }

      .active {
        line-height: 5vh;
        height: 100%;
        background: #C70000;
      }
    }
  }
}

.content {
  width: 70%;
  margin: 0 auto;
}

.fixed {
  position: fixed;
  top: 0;
  z-index: 9;
}

.img:hover {
  transform: scale(1.3);
}
</style>