<template>
  <div class="drop">
    <div class="text" v-for="(item, index) in propItemList" :key="item.id" @click="handleJump(item.id)">
      <img :src="item.image" alt="" srcset="">
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  props: {
    propItemList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    //跳到列表页
    handleJump(id) {
      if (this.$route.path == '/product') {
        this.$bus.$emit('getList', id)
        this.$router.push({
          path: `/product?id=${id}`
        })
      } else {
        this.$router.push({
          path: '/product',
          query: {
            id: id
          }
        })
      }
    }
  },
  created() { },
  mounted() { }
}
</script>

<style lang="less" scoped>
.drop {
  // padding: 10px;
  min-width: 100px;

  .text {
    font-size: 14px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    padding:0 10px 5px 10px;
    box-sizing: border-box;

    img {
      padding-right: 5px;
    }
  }

  .text:nth-child(1) {
    padding-top: 5px;
  }
}

.text:hover {
  background: #F3F3F3;
  border-radius: 2px;
  cursor: pointer;
}</style>