import {post, get} from '@/utils/request'

//获取新闻列表
export const newsList = (data)=> post('/api/article/getList',data)

//获取新闻分类
export const newsClassify = (data)=> post('/api/category/index',data)

//获取新闻详情
export const newsInfo = (data)=> post('/api/article/detail',data)

//获取常见问题
export const question = ()=> post('/addons/shopro/faq/index')

//获取商品评论信息
export const goodsReviews = (data)=> post('/addons/shopro/goods_comment/getAllList',data)

//留言
export const message = (data)=> post('/addons/shopro/msg/add',data)
