<template>
  <div class="swiperBox">
    <!-- <img src="@/assets/images/additional2.png" class="fixed-image">
    <swiper class="swiper box1" :options="swiperOption">
      <swiper-slide class="slide img" v-for="(item, index) of imgList[0].children" :key="index" @click="handeJump(item.id)">
        <div class="content" :data-id="item.id">
          <img :src="item.image"/>
          <div class="img_title">FOR {{ item.name }}</div>
        </div>
      </swiper-slide>
    </swiper> -->
    <div class="swiper_item" @click="handeJump('178')">
      <img src="@/assets/images/her.png" alt="">
      <div class="text">
        FOR HER
      </div>
    </div>
    <div class="swiper_item" @click="handeJump('179')">
      <img src="@/assets/images/him1.png" alt="">
      <div class="text">
        FOR HIM
      </div>
    </div>
    <div class="swiper_item">
      <div class="head">Crystal gifts & <br /> Crafts To <br /> Buying For</div>
      <div class="icon">+</div>
    </div>
    <div class="swiper_item" @click="handeJump('180')">
      <img src="@/assets/images/family.png" alt="">
      <div class="text">
        FOR FAMILY
      </div>
    </div>
    <div class="swiper_item" @click="handeJump('181')">
      <img src="@/assets/images/pets.png" alt="">
      <div class="text">
        FOR PETS
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'////这里注意具体看使用的版本是否需要引入样式，以及具体位置。
var vm = null
export default {
  name: 'carousel',
  components: {
    swiper,
    swiperSlide
  },
  props: ['imgList'],
  data() {
    return {
      // swiperOption: {
      //   on: {
      //     tap: function (e) {
      //       let dom = e.target.parentNode;
      //       vm.handeJump(dom.dataset.id)
      //     }
      //   },
      //   autoplay: {
      //     delay: 2500,
      //     disableOnInteraction: false
      //   },
      //   // loop: true,
      //   speed: 1000,
      //   slidesPerView: 5,
      //   spaceBetween: 30,
      //   navigation: {
      //     nextEl: '.swiper-button-next', //前进按钮的css选择器或HTML元素。
      //     prevEl: '.swiper-button-prev', //后退按钮的css选择器或HTML元素。
      //     hideOnClick: true, //点击slide时显示/隐藏按钮
      //     disabledClass: 'my-button-disabled', //前进后退按钮不可用时的类名。
      //     hiddenClass: 'my-button-hidden', //按钮隐藏时的Class
      //   },
      // },
    }
  },
  methods: {
    //跳至产品列表页
    handeJump(id) {
      this.$router.push({
        path: '/product',
        query: {
          id: id
        }
      })
    },
  },
  mounted() { },
  created() {
    vm = this
    // this.list = imgList
  }
}
</script>
<style scoped lang='less'>
.swiperBox {
  width: 100%;
  position: relative;
  // margin: 20px 0;
  display: flex;

  .swiper_item {
    width: calc((100% - 40px) / 4);
    margin-right: 5px;
    // height: max-content;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
    box-shadow: 0px 5px 5px 0px rgba(187, 187, 187, 0.75);
    cursor: pointer;
    img {
      display: inline-block;
      width: 100%;
      // height: 100%;
    }

    .text {
      padding-top: 10px;
      font-size: 16px;
      font-family: Arial;
      font-weight: bold;
      color: #333333;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }
  }

  .swiper_item:nth-child(3) {
    background: #C70000;
    box-shadow: 0px 5px 5px 0px rgba(187, 187, 187, 0.75);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .head {
      font-size: 20px;
      font-family: Arial;
      font-weight: bold;
      color: #FEFEFE;
      line-height: 25px;
      margin: 40px 0;
    }

    .icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-weight: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      border: 3px solid #fff;
      box-shadow: 0px 5px 5px 0px rgba(187, 187, 187, 0.75);
    }
  }

  .swiper_item:nth-last-child(1) {
    margin-right: 0;
  }

  // .content {
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-direction: column;

  //   img {
  //     width: 200px;
  //     height: 200px;
  //   }

  //   .img_title {
  //     padding-top: 10px;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 2; //控制显示几行
  //   }
  // }
}
</style>