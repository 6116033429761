import axios from 'axios'
import { Message } from 'element-ui';
import router from '../router/index'
import store from '@/store/index'

const instance = axios.create({
    baseURL:process.env.VUE_APP_BASE_URL,
    timeout:12000
});
//请求拦截器
instance.interceptors.request.use(
    config=>{
        let token = window.localStorage.getItem('token')
        if(token){
            config.headers['token'] = token
        }
       return config;
    },
    err=>{}
);

// 响应拦截器
instance.interceptors.response.use(
    config=>{
        if (config.data.code !== 1) {
            Message.error(config.data.msg);
            // if(config.data.code == 1001){
            //     //未登录返回code为1001
            //     store.commit('logout')
            //     router.push({path: '/login'})
            //     return config.data
            // }else{
                return Promise.reject(new Error(config.data.msg || 'Error'))
            // }
        }else{
             return config.data.data;
        }      
    },
    err=>{
        Message.warning({
            message: err.message,
            type: 'warning'
          });
        return Promise.reject(err)
    }
);

//封装get请求，格式：www.baidu.com?id=1&pwd=123
export function get(url,params){
    return instance.get(url,{params});
};

//封装post请求
export function post(url,data){
    return instance.post(url,data,{
        headers: {"Content-Type":"application/json;charset=utf-8"}
        // headers: {"Content-Type":"multipart/form-data; boundary=----WebKitFormBoundarya9VxzAIZf3BFxZB1"}
    });
}

//封装delete请求
export function Delete(url,data){
    return instance.delete(url,{params:data},{
        transformRequest:[
            function(data){
                let str ='';
                for(let key in data){
                    str += encodeURIComponent(key) + '=' +
                            encodeURIComponent(data[key]) + '&'
                }
                // console.log(str);
                return str;
            }
        ],
        headers:{
            "Content-Type":"application/x-www-form-urlencoded"
        }
    });
}

//分装put请求
export function put(url,data){
    return instance.put(url,data,{
        transformRequest:[
            function(data){
                let str ='';
                for(let key in data){
                    str += encodeURIComponent(key) + '=' +
                            encodeURIComponent(data[key]) + '&'
                }
                // console.log(str);
                return str;
            }
        ],
        headers:{
            "Content-Type":"application/x-www-form-urlencoded"
        }
    });
};
