import {post, get} from '@/utils/request'

//账号/邮箱登录
export const login = (data)=> post('/api/user/login', data)

//退出登录
export const loginOut = (data)=> post('/api/user/logout', data)

//刷新用户信息
export const refresh = ()=> post('/api/user/refresh')

//修改用户信息
export const editInfo = (data)=> post('/addons/shopro/user/profile',data)

//修改密码
export const editPw = (data)=> post('/addons/shopro/user/changePwd',data)

//获取邮箱验证码
export const code = (data)=> post('/api/ems/send',data)

//修改密码
export const resetPw = (data)=> post('/api/user/resetpwd',data)

//注册账号
export const register = (data)=> post('/api/user/register',data)

//个人中心
export const userInfo = ()=> post('/api/user/index')

//地址列表
export const addressList = ()=> post('/addons/shopro/address/index')

//新增/修改地址列表
export const editAddress = (data)=> post('/addons/shopro/address/edit',data)

//删除地址列表
export const delAddress = (data)=> post('/addons/shopro/address/del',data)

//设置默认地址
export const defaultAddress = (data)=> post('/addons/shopro/address/defaults',data)

//获取系统配置
export const sysConfig = (data)=> post('/api/index/getSysConfig',data)

//获取个人评论
export const comment = (data)=> post('/api/user/comments',data)

//获取页面关键字描述等
export const getKey = (data)=> post('/api/index/getKeyByPath',data)