import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//引入自定义图标
import "./assets/css/icon.css"

//引入初始化css
import "./style/normal.css"

//引入公共css
import "./style/index.css"

// 引入自定义字体
import './assets/font/font.css'

import api from '@/api/index'
Vue.prototype.api = api

import lang from '@/lang/index.js'
Vue.prototype.l = lang.lang

//将bus挂载在vue原型上
Vue.prototype.$bus = new Vue()

//引入elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
// 设置语言
Vue.use(ElementUI, { locale })

//时间戳过滤器
Vue.filter('timestampToTime', function (timestamp) {
  var date = new Date(timestamp * 1000);
  var Y = date.getFullYear() + "-";
  var M =(date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h = date.getHours() < 10 ? '0' + date.getHours() + ":" : date.getHours() + ":";
  var m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ":" : date.getMinutes() + ":";
  var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
})
//时间戳过滤器
Vue.filter('timestampToTime1', function (timestamp) {
  var date = new Date(timestamp * 1000);
  var Y = date.getFullYear();
  var M =(date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "/";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/";
  return  D + M + Y;
})

//注册全局组件
import Pagination from '@/components/Pagenation';
Vue.component(Pagination.name, Pagination)

// Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
