import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: localStorage.getItem('changeLang') ? localStorage.getItem('changeLang') : 'zh-CN',
    personalShow: false,
    user: {},
    isLogin: false,
    token: window.localStorage.getItem('token'),
    lang: 'en'
  },
  getters: {},
  mutations: {
    // 同步操作直接修改state里面的数据
    setLanguage: (state, data) => {
      state.language = data;
      localStorage.setItem('changeLang', data);
    },
    login(state, user) {
      state.user = user
      state.isLogin = true
      window.localStorage.setItem('token', user.token)
      delete user.token
      window.localStorage.setItem('userInfo', JSON.stringify(user))
    },
    logout(state) {
      window.localStorage.clear()
      state.token = ''
      state.user = {}
      state.isLogin = false
    },
    changeLang(state, l){
      state.lang = l
    }
  },
  actions: {},
  modules: {}
})
