<template>
  <div class="container">
    <div class="text">
      <div class="text_content" :class="index==currentIndex?'actived':''" v-for="(item, index) in list" :key="index" @click="gotoPage(index)">
        {{ item.text }}
      </div>
    </div>
    <div class="lunbo">
      <div class="img">
        <img :src="dataList[currentIndex]" alt="" />
      </div>
      <div class="dooted" v-if="this.dataList.length">
        <ul class="doo">
          <li v-for="(item, index) in this.dataList" :key="index" :class="{ current: currentIndex == index }"
            @click="gotoPage(index)"></li>
        </ul>
      </div>
      <div class="right turn" @click="next()">
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="left turn" @click="up()">
        <i class="el-icon-arrow-left"></i>
      </div>
    </div>

  </div>
</template>
  
<script>
export default {
  data() {
    return {
      dataList: [
        require('@/assets/images/511/home/1.png'),
        require('@/assets/images/511/home/2.png'),
        require('@/assets/images/511/home/3.png'),
        require('@/assets/images/511/home/4.png'),
        require('@/assets/images/511/home/5.png'),
        require('@/assets/images/511/home/6.png'),
        require('@/assets/images/511/home/7.png'),
        // require('@/assets/images/511/home/8.png'),
        // require('@/assets/images/511/home/9.png'),
        // require('@/assets/images/511/home/10.png'),
        // require('@/assets/images/511/home/11.png'),
      ],
      list: [
        {
          id: 0,
          text: 'Awards & Trophy '
        },
        {
          id: 1,
          text: 'Rhinestones Wholesale'
        },
        {
          id: 2,
          text: 'Beads In Bulk '
        },
        {
          id: 3,
          text: 'Decor Your Houses '
        },
        {
          id: 4,
          text: 'Crystal Accessories '
        },
        {
          id: 5,
          text: 'Crystal Packages '
        },
        {
          id: 6,
          text: 'Materials For Factory'
        },
      ],
      currentIndex: 0, // 默认显示图片
      timer: null // 定时器
    }
  },
  created() {
    // this.run()
  },
  methods: {
    gotoPage(index) {
      console.log(index);
      this.currentIndex = index
    },
    next() {
      if (this.currentIndex === this.dataList.length - 1) {
        this.currentIndex = 0
      } else {
        this.currentIndex++
      }
    },
    up() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.dataList.length - 1
      } else {
        this.currentIndex--
      }
    },
    // clear() {
    //     clearInterval(this.timer)
    // },
    // 定时器
    // run() {
    //     this.timer = setInterval(() => {
    //         this.next()
    //     }, 2000)
    // },
  }
}
</script>
  
<style lang="less" scoped>
ul li {
  float: left;
  width: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: rgba(240, 238, 238, 0.8);
  font-size: 14px;
}

.container {
  position: relative;
  height: 550px;
  width: 100%;
  padding: 30px 0;
  margin: 0 auto;

  .text {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;

    .text_content {
      width: 200px;
      height: 60px;
      text-align: center;
      word-wrap: break-word;
      font-size: .9rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text_content:hover {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      background: #C70000;
      box-shadow: 0px 5px 15px 0px rgba(199, 0, 0, 0.45);
      border-radius: 30px;
      cursor: pointer;
      color: #FFFFFF;
    }
  }

  .img {
    height: 450px;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .dooted {
    position: absolute;
    bottom: -10px;
    right: 0px;
  }
}

.actived {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: #C70000;
  box-shadow: 0px 5px 15px 0px rgba(199, 0, 0, 0.45);
  border-radius: 30px;
  cursor: pointer;
  color: #FFFFFF;
}

.turn {
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #A5A5A5;
  border: 1px solid #DFDFDF;
}

.turn:hover {
  background: #C70000;
  color: #FEFAFA;
}

.right {
  position: absolute;
  top: 50%;
  right: -65px;
}

.left {
  position: absolute;
  top: 50%;
  left: -65px;
}

.current {
  color: gray;
}
</style>
  