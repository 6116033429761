<template>
  <div class="swiperBox">
    <div class="left turn" @click="clickLeft">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="right turn" @click="clickRight">
      <i class="el-icon-arrow-right"></i>
    </div>
    <div id="swiper">
      <div class="imgBox" @mouseenter="handleEnter" @mouseleave="handleLeave">
        <div class="imgDiv" v-for="(item, index) of imgList" :key="index">
          <div class="content" @click="handeJump(item.id)">
            <img :src="item.image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'carousel1',
  props: {
    speed: Number,
    direction: String,
    imgList: {
      default: [],
      type: Array
    },
  },
  data() {
    return {
      width: '100%',
      timer: null,
      theSpeed: 1,
      imgWidth: 260,
      theDirection: this.direction,
    }
  },
  methods: {
    //向左滚动
    clickLeft() {
      this.theDirection = 'left';
    },
    //向右移动
    clickRight() {
      this.theDirection = 'right';
    },
    //鼠标移入停止
    handleEnter() {
      this.theSpeed = null
    },
    //鼠标移出开始滚动
    handleLeave() {
      this.theSpeed = 1
    },
    //跳至详情页
    handeJump(id) {
      this.$router.push({
        name: 'goodsInfo',
        params: {
          id: id
        }
      })
    }
  },
  created() {

  },
  mounted() {
    // setTimeout(() => {
    let box = document.getElementsByClassName('imgBox')[0];
    //将box下的图片进行拼接 循环展示图片
    box.innerHTML += box.innerHTML;
    let img = document.getElementsByClassName('img');
    // box.style.width = img.length * this.imgWidth + 'px';//设置div的宽度使图片可以放下
    let self = this;
    function autoScroll() {
      if (box.offsetLeft < -(box.offsetWidth / 2)) {//提前更新left值，实现循环展示
        box.style.left = 0;
      }
      if (box.offsetLeft > 0) {//向右滚动 提前更新left值，实现循环展示
        box.style.left = -(box.offsetWidth / 2) + 'px';
      }
      if (self.theDirection == 'left') { //向左滚动,值为负
        self.theSpeed = -Math.abs(self.theSpeed)
      } else { //向右滚动
        self.theSpeed = Math.abs(self.theSpeed)
      }
      // 求出总的left值，等于left值加上移动的速度（px值）
      box.style.left = box.offsetLeft + self.theSpeed + 'px';
    }
    this.timer = setInterval(autoScroll, 30);//全局变量 ，保存返回的定时器
    // }, 500);

  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
}
</script>
<style scoped lang='less'>
.swiperBox {
  width: 100%;
  height: 30vh;
  position: relative;
  padding-top: 20px;

  #swiper {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    .imgBox {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      display: flex;

      .imgDiv {
        width: 100%;
        margin-left: 15px;
        text-align: center;

        .content {
          text-align: center;
          width: 15vw;
          // border: 1px solid #DDDDDD;
          border-radius: 5px;

          img {
            height: 15vw;
            width: 15vw;
          }
        }

      }
    }
  }
}

.turn {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #d0d0d073;
}

.right {
  position: absolute;
  top: 17vh;
  right: .5vw;
}

.left {
  position: absolute;
  top: 17vh;
  left: .5vw;
}
</style>