<template>
  <div id="heaer_container">
    <div class="header_top">
      <div class="top_content">
        <span class="phone" v-if="config.phone">{{ config.phone }}</span>
        <span class="email" v-if="config.email">{{ config.email }}</span>
      </div>
    </div>
    <div class="header_content">
      <div class="header_body">
        <div class="header_logo" @click="$router.push('/')">
          <img src="@/assets/images/logo.png" alt="" srcset="">
        </div>
        <div class="header_search">
          <div class="search_top">
            <i class="icon-user"></i>
            <input @keydown="handleSearch" clearable v-model.trim="keywords" type="text" class="user" />
            <button @click="handleClick()">Search</button>
          </div>
          <div class="search_foot">
            <span class="foot_text" v-for="(item, index) in keyWordsList" :key="index"
              @click="handleKeywords(item.keyword)">{{ item.keyword }}</span>
          </div>
        </div>
        <div class="header_btn">

          <div class="btn_content" style="width: 101px;">
            <div class="btn_top">
              <img src="@/assets/images/user.png" alt="" srcset="">
            </div>
            <div v-if="userinfo == null || !userinfo.username" class="btn_foot" @click="handleJump">{{ l("login") }}</div>
            <el-dropdown v-else @command="handleCommandLogin">
              <span class="el-dropdown-link username">
                {{ userinfo.username }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="user">My Center</el-dropdown-item>
                <el-dropdown-item command="loginout">Sign Out</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="btn_content" @click="handleGoCart">
            <div class="btn_top">
              <img src="@/assets/images/buyCar.png" alt="" srcset="">
            </div>
            <div class="btn_foot">{{ l("cart") }}</div>
          </div>
          <div class="btn_content" style="padding-right: 0;">
            <div class="btn_top">
            </div>
            <div class="btn_foot">
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ text }}<i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in drowList" :command='item.value' :key="item.value">{{
                    item.label }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header_foot" :class="{ fixed: scoll > 20 }">
      <div class="foot_content">
        <div class="foot_nav" :class="{ active: currentId == 0 }" v-for="(item, index) in category" :key="index">
          <el-dropdown>
            <span class="el-dropdown-link"><!-- 购买 -->
              {{ item.name }}
            </span>
            <el-dropdown-menu class="menu" slot="dropdown">
              <!-- <el-dropdown-item class="actived">  -->
              <Drop v-bind:propItemList="item.children"></Drop>
              <!-- </el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="foot_nav" :class="{ active: currentId == 7 }" @click="$router.push('/bulk_Purchase')">Bulk Purchase
        </div><!--  批量采购-->
      </div>
    </div>
  </div>
</template>

<script>
import Drop from '../drop/index.vue'
export default {
  data() {
    return {
      keywords: "",
      token: localStorage.getItem('token'),
      category: JSON.parse(localStorage.getItem('category')),
      scoll: 0,
      language: 'zh',
      icon: "el-icon-him",
      text: "select language",
      drowList: [
        {
          value: "zh",
          label: '简体中文',
        },
        {
          value: "en",
          label: 'English',
        },
        // {
        //     id: ,
        //     name: '日语',
        // }
      ],
      propList: [
        {
          id: 0,
          src: require("@/assets/images/him.png"),
          itemName: "him"
        },
        {
          id: 1,
          src: require("@/assets/images/him.png"),
          itemName: "him"
        },
        {
          id: 2,
          src: require("@/assets/images/him.png"),
          itemName: "him"
        },
        {
          id: 3,
          src: require("@/assets/images/him.png"),
          itemName: "him"
        }
      ],
      config: {},
      keyWordsList: []
    }
  },
  props: {
    currentId: String
  },
  components: {
    Drop
  },
  computed: {
    userinfo: {
      get() {
        return this.$store.state.user
      },
      set(value) {
        this.$store.commit('login', value)
      }
    }
  },
  methods: {
    //获取屏幕滚动的高度
    scrollTop() {
      this.scoll = document.documentElement.scrollTop
    },
    changeLang() {
      sessionStorage.setItem("theLanguage", this.language)
      this.askSide() // 刷新当前页面
    },
    handleCommand(command) {
      this.$store.commit('changeLang', command)
      let index = this.drowList.findIndex(item => {
        return item.value == command
      })
      this.text = this.drowList[index].label
      sessionStorage.setItem("theLanguage", this.language)
      //  this.askSide() // 刷新当前页面
    },
    handleJump() {
      this.$router.push("/login")
      this.$emit('header', false);
      this.$emit('footer', false);
    },
    //头像下拉
    handleCommandLogin(e) {
      if (e == 'user') {
        this.$router.push('/personal')
      } else {
        this.api.user.loginOut().then(res => {
          localStorage.clear()
          this.$store.state.token = ''
          this.$store.state.user = {}
          this.$store.state.isLogin = false
        })
      }
    },
    //跳到购物车
    handleGoCart() {
      if (!this.token) {
        this.$message.warning("请登录后操作")
        retutn
      } else {
        this.$router.push('/personal')
      }
    },
    //点击关键字搜索
    handleKeywords(val) {
      this.keywords = val
      this.handleClick()
    },
    //搜索商品
    handleClick() {
      if (this.$route.path == '/product') {
        this.$router.push({
          path: `/product?keywords=${this.keywords}`
        })
        this.$bus.$emit('keyword', this.keywords)
      } else {
        this.$router.push({
          path: '/product',
          query: {
            keywords: this.keywords
          }
        })
      }
      // this.keywords = ''
    },
    //键盘回车事件
    handleSearch(e) {
      if (e.keyCode == '13') {
        this.handleClick()
      }
    },
    //刷新个人信息
    getUserInfo() {
      this.api.user.refresh().then(res => {
        this.$store.commit('login', res)
      })
    },
    //获取菜单分类
    getCategory() {
      this.api.goods.category().then(res => {
        localStorage.setItem('category', JSON.stringify(res))
      })
    },
    //获取系统配置
    getSysConfig() {
      this.api.user.sysConfig().then(res => {
        this.config = res
      })
    },
    //获取系统配置
    getKeyword() {
      this.api.goods.keyword().then(res => {
        this.keyWordsList = res.list
      })
    }
  },
  created() {
    this.getCategory()
  },
  mounted() {
    if (this.token) {
      this.getUserInfo()
    }
    this.getCategory()
    this.getSysConfig()
    this.getKeyword()
  },
}
</script>

<style lang="less" scoped>
#heaer_container {
  width: 100%;
  // height: 24.9vh;

}

.header_top {
  width: 100%;
  height: 5vh;
  background: #EFEFEF;
  position: relative;

  .top_content {
    width: 70%;
    height: 5vh;
    line-height: 5vh;
    margin: 0 auto;
    text-align: right;

    .phone {
      padding-right: 30px;
    }

    .phone,
    .email {
      font-size: 16px;
      font-family: Arial;
      font-weight: 400;
      color: #333333;
      line-height: 10px;
    }
  }



}

.header_content {
  // display: contents;
  width: 100%;
  // height: 15vh;
  // line-height: 15vh;
  position: relative;

  .header_body {
    width: 70%;
    // position: absolute;
    // left: 50%;
    // top: 0;
    // transform: translate(-50%);
    // text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 5vh;

    .header_logo {
      cursor: pointer;
      img {
        width: 100%;
      }
    }

    .header_search {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .search_top {
        position: relative;
        width: 450px;
        display: flex;
        border: 3px solid #C70000;
        border-radius: 5px;
        box-sizing: border-box;

        .icon-user {
          position: absolute;
          top: 20%;
          left: 5px;
          z-index: 5;
          background-image: url(@/assets/images/search.png);
          /*引入图片图片*/
          background-repeat: no-repeat;
          /*设置图片不重复*/
          background-position: 0px 0px;
          /*图片显示的位置*/
          width: 20px;
          /*设置图片显示的宽*/
          height: 20px;
          /*图片显示的高*/
          padding-right: 10px;
        }

        .user {
          width: 330px;
          height: 25px;
          padding-left: 30px;
          border: none;
          outline: none;
          box-sizing: border-box;
        }

        .user:focus {
          outline: none;
        }

        button {
          width: 120px;
          background: #C70000;
          border-radius: 0px 3px 3px 0px;
          border: none;
          color: #FFFFFF;
          height: 27px;
          margin: -1px -1px -1px 0;
        }

        button:hover {
          cursor: pointer;
        }
      }

      .search_foot {
        padding-top: 10px;

        span {
          font-size: 14px;
          font-family: Jost;
          font-weight: 400;
          color: #C70000;
          line-height: 16px;
          padding: 0 5px;
          border-right: 1px #C70000 solid;
          cursor: pointer;
        }

        span:nth-child(5) {
          border-right: none;
        }

        span:nth-child(1) {
          padding-left: 0;
        }
      }
    }

    .header_btn {
      display: flex;
      justify-content: space-between;

      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }

      .username {
        color: #333;
      }

      .el-icon-arrow-down {
        font-size: 12px;
      }

      .btn_content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-right: 30px;

        .btn_top {
          width: 34px;
          height: 40px;
        }

        .btn_top img {
          width: 34px;
          height: 34px;
        }
      }
    }
  }

}

.header_foot {
  width: 100%;
  height: 5vh;
  background: #1B2E4E;
  position: relative;


  .foot_content {
    width: 70%;
    height: 5vh;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .foot_nav {
      height: 5vh;
      font-size: 20px;
      font-family: Jost;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 5vh;
      padding: 0 10px;
      box-sizing: border-box;

      .el-dropdown-link {
        cursor: pointer;
        font-size: 20px;
        font-family: Jost;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 5vh;
      }
    }

    .foot_nav:hover {
      // width: 100px;
      background: #C70000;
      cursor: pointer;
    }
  }
}

.menu {
  padding: 0 !important;
  margin: 0 !important;
}

.active {
  background: #C70000;
  cursor: pointer;
}

.fixed {
  position: fixed;
  top: 0;
  z-index: 9;
}

.btn_foot {
  font-size: 16px;
  font-family: Arial;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  cursor: pointer;
}
</style>