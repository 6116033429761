import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/index.vue'
import {
  Message
} from 'element-ui';
import store from '@/store/index'
import api from '@/api/index'
//引入动态title
import getPageTitle from '../utils/getTitle'

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'homepage',
    component: () => import("../views/companyHomepage/index.vue"),
    meta: {
      title: '官网首页',
      showHead: 'websiteHeader',
      showFoot: 'true',
      currentId: "-1",
      keywords: 'home',
      description: 'this is home'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      title: '商城首页',
      showHead: 'shopHeader',
      showFoot: true
    }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import("../views/product/index.vue"),
    meta: {
      title: '商品中心',
      showHead: 'shopHeader',
      showFoot: true
    }
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import("../views/personal/index.vue"),
    meta: {
      title: '个人中心',
      showHead: '',
      showFoot: true
    }
  },
  {
    path: '/goodsInfo/:id',
    name: 'goodsInfo',
    component: () => import("../views/goodsInfo/index.vue"),
    meta: {
      title: '商品详情',
      showHead: 'shopHeader',
      showFoot: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("../views/login/index.vue"),
    meta: {
      title: '登录',
      showHead: '',
      showFoot: false
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import("../views/contact/index.vue"),
    meta: {
      title: '联系我们',
      showHead: 'websiteHeader',
      showFoot: true
    }
  },
  {
    path: '/oemservice',
    name: 'oemservice',
    component: () => import("../views/collections/index.vue"),
    meta: {
      title: '定制',
      showHead: 'websiteHeader',
      showFoot: true,
      currentId: "1"
    }
  },
  {
    path: '/review',
    name: 'review',
    component: () => import("../views/review/index.vue"),
    meta: {
      title: '评价列表',
      showHead: 'websiteHeader',
      showFoot: true,
      currentId: "4"
    }
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import("../views/brand/index.vue"),
    meta: {
      title: '品牌原则',
      showHead: 'websiteHeader',
      showFoot: true,
      currentId: "2"
    }
  },
  {
    path: '/factory',
    name: 'factory',
    component: () => import("../views/factory/index.vue"),
    meta: {
      title: '工厂',
      showHead: 'websiteHeader',
      showFoot: true,
      currentId: "0"
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import("../views/faq/index.vue"),
    meta: {
      title: 'FAQ',
      showHead: 'shopHeader',
      showFoot: true,
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import("../views/news/index.vue"),
    meta: {
      title: 'NEWS',
      showHead: 'shopHeader',
      showFoot: true,
    }
  },
  {
    path: '/ecatalogue',
    name: 'ecatalogue',
    component: () => import("../views/download/index.vue"),
    meta: {
      title: '产品手册下载',
      showHead: 'websiteHeader',
      showFoot: true,
      currentId: "5"
    }
  },
  {
    path: '/newsInfo/:id',
    name: 'newsInfo',
    component: () => import("../views/newsInfo/index.vue"),
    meta: {
      title: '新闻详情',
      showHead: 'shopHeader',
      showFoot: true,
    }
  },
  {
    path: '/bulk_Purchase',
    name: 'bulk_Purchase',
    component: () => import("../views/buyMore/index.vue"),
    meta: {
      title: '批量购买',
      showHead: 'shopHeader',
      showFoot: true,
      currentId: "7"
    }
  },
  {
    path: '/ship',
    name: 'ship',
    component: () => import("../views/ship/index.vue"),
    meta: {
      title: 'Shipping',
      showHead: 'shopHeader',
      showFoot: true,
      currentId: "-1"
    }
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: () => import("../views/sitemap/index.vue"),
    meta: {
      title: '网站地图',
      showHead: 'websiteHeader',
      showFoot: false,
      currentId: "-1"
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import("../views/404/index.vue"),
    meta: {
      title: '404',
      showHead: false,
      showFoot: false
    }
  },
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  //设置滚动条的位置
  scrollBehavior() {
    return {
      y: 0
    };
  },
  routes
})
let isLogin = localStorage.getItem("token")
api.user.getKey({
  path: "/"
}).then(res => {

})
 


router.beforeEach((to, from, next) => {
  if(to.path!='/goodsInfo'){
    api.user.getKey({
      path: to.path
    }).then(res => {
      if (Object.keys(to.meta).length > 0 && to.matched.length > 0) {
        // let this_meta = to.matched[to.matched.length - 1].meta
        document.title = res.name
        let head = document.getElementsByTagName('head');
        let meta_keyword = document.createElement('meta');
        if (document.querySelector('meta[name="keywords"]')) {
          document.querySelector('meta[name="keywords"]').setAttribute('content', res.key)
        } else {
          meta_keyword.setAttribute('name', 'keywords')
          meta_keyword.setAttribute('content', res.key)
          head[0].appendChild(meta_keyword)
        }
        let meta_description = document.createElement('meta');
        if (document.querySelector('meta[name="description"]')) {
          document.querySelector('meta[name="description"]').setAttribute('content', res.desc)
        } else {
          meta_description.setAttribute('name', 'description')
          meta_description.setAttribute('content', res.desc)
          head[0].appendChild(meta_description)
        }
      }
    })
  }
 
  if (!to.path) {
    next('/404')
    // } else if (to.path == '/login') {
    //   if (!isLogin) {
    //     next()
    //   } else {
    //     api.user.refresh().then((res) => {
    //      store.commit('login', res)
    //     })
    //     Message.success('您已经登录啦')
    //   }
  } else {
    next()
  }
  // if (to.meta.title) {
  //   document.title = getPageTitle(to.meta.title);
  // } else {
  //   document.title = "金果商城"
  // }
});

export default router
