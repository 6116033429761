<template>
  <div id="footer_container">
    <div class="footer_header">
      <div class="footer_top active">
        <div class="top_img">
          <img src="../../assets/images/pay.png" alt="" srcset="">
        </div>
        <div class="top_img">
          <img src="../../assets/images/visa.png" alt="" srcset="">
        </div>
        <div class="top_img">
          <img src="../../assets/images/circle.png" alt="" srcset="">
        </div>
        <div class="top_img">
          <img src="../../assets/images/circle1.png" alt="" srcset="">
        </div>
      </div>
      <div class="footer_top active">
        <div class="top_img">
          <img src="../../assets/images/ems.png" alt="" srcset="">
        </div>
        <div class="top_img">
          <img src="../../assets/images/dhl.png" alt="" srcset="">
        </div>
        <div class="top_img">
          <img src="../../assets/images/ups.png" alt="" srcset="">
        </div>
        <div class="top_img">
          <img src="../../assets/images/fed.png" alt="" srcset="">
        </div>
      </div>
      <div class="footer_top">
        <div class="top_img" id="img">
          <a href="#"><img src="../../assets/images/f.png" alt="" srcset=""></a>
        </div>
        <div class="top_img" id="img">
          <a href="#"><img src="../../assets/images/bird.png" alt="" srcset=""></a>
        </div>
        <div class="top_img" id="img">
          <a href="#"> <img src="../../assets/images/in.png" alt="" srcset=""></a>
        </div>
        <div class="top_img" id="img">
          <a href="#"><img src="../../assets/images/camera.png" alt="" srcset=""></a>
        </div>
        <div class="top_img" id="img">
          <a href="#"><img src="../../assets/images/triangle.png" alt="" srcset=""></a>
        </div>
        <div class="top_img" id="img">
          <a href="#"><img src="../../assets/images/headset.png" alt="" srcset=""></a>
        </div>
      </div>
    </div>
    <div class="footer_content">
      <div class="content">
        <ul>
          <li>
            <a href="#/">About</a>
          </li>
          <li>
            <a href="#/factory">Crystal Manufacturer</a>
          </li>
          <li>
            <a href="#/oemservice">OEM Service</a>
          </li>
          <li>
            <a href="#/brand">Brand Principles</a>
          </li>
        </ul>
      </div>
      <div class="content">
        <ul>
          <li><a href="#/bulk_Purchase">Bulk Merchandise</a></li>
          <li><a href="#/download">Amazing Collections</a></li>
          <!-- <li><a href="#/download">E-catelogues</a></li> -->
          <li><a href="#/contact">Contacts</a></li>
        </ul>
      </div>
      <div class="content">
        <ul>
          <li><a href="#/review">Reviews</a></li>
          <li><a href="#/news">News</a></li>
          <li><a href="#/news?id=7">Knowledge</a></li>
          <li><a href="#/faq">FAQs</a></li>
        </ul>
      </div>
      <div class="content">
        <ul>
          <li><a href="#/ship?type=ship">Shipping Informs</a></li>
          <li><a href="#/ship?type=pay">Payment Methods</a></li>
          <li><a href="#/ship?type=solutions">Return Solutions</a></li>
          <li><a href="#/sitemap">Sitemap</a></li>
        </ul>
      </div>
    </div>
    <div class="footer_footer">
      <div class="footer_address">Add:<span v-if="config.address"> {{ config.address }}</span></div>
      <div class="footer_tel"> Tel:<span v-if="config.phone">{{config.phone  }}</span></div>
      <div class="footer_email"> Email:<span v-if="config.email">{{config.email  }}</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      config: {}
    }
  },
  methods: {
    //获取系统配置
    getSysConfig() {
      this.api.user.sysConfig().then(res => {
        this.config = res
      })
    }
  },
  created() { },
  mounted() {
    this.getSysConfig()
  }
}
</script>

<style lang=less scoped>
#footer_container {
  width: 100%;
  height: 30vh;
  background: #1B2E4E;
  bottom: 0;
  // position: absolute;
}

.footer_header {
  // width: 100%;
  height: 5vh;
  background: #EDEDED;
  display: flex;
  padding: 0 15vw;
  justify-content: space-between
}

.footer_top {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.active {
  padding-right: 80px;
}

.footer_top .top_img {
  padding-right: 10px;

  img {
    width: 3vw;
    height: 4vh;
  }
}

#img img {
  width: 1.5vw;
  height: 1.5vw;
}

.footer_top:nth-last-child(1) {
  padding-top: 10px;
}

.footer_top .top_img:nth-last-child(1) {
  padding-right: 0;
}

.footer_content {
  // width: 100%;
  height: 20vh;
  display: flex;
  justify-content: space-between;
  padding: 0 15vw;
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
  ;

  .content {
    padding-top: 2.5vh;
    //    padding-left: 8vw;
  }
}

ul {
  text-align: center;
}

ul>li {
  font-size: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 25px;
  padding-bottom: 6px;
}

ul>li:nth-last-child(1) {
  padding-bottom: 0;
}

.footer_footer {
  height: calc(5vh-2px);
  display: flex;
  justify-content: space-between;
  padding: 0 15vw;
  line-height: 4.2vh;

  div {
    font-size: 12px;
    font-family: Jost;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.4;
  }
}</style>