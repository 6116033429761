import {post, get} from '@/utils/request'

//获取商品菜单分类
export const category = ()=> post('/api/category/index')

//获取商品列表
export const goodsList = (data)=> get('/addons/shopro/goods/lists',data)

//获取商品详情
export const goodsInfo = (data)=> get('/addons/shopro/goods/detail',data)

//添加购物车
export const addCart = (data)=> post('/addons/shopro/cart/add',data)

//获取购物车数据
export const cartList = ()=> get('/addons/shopro/cart/index')

//修改购物车数据
export const editCartList = (data)=> post('/addons/shopro/cart/edit',data)

//商品收藏
export const collect = (data)=> post('/addons/shopro/goods/favorite',data)

//商品收藏列表
export const collectList = (data)=> post('/addons/shopro/goods/favoriteList',data)

//热销商品列表
export const hotGoodsList = (data)=> post('/addons/shopro/goods/hotList',data)

//仓库地址
export const warehouseList = (data)=> get('/addons/shopro/category/index',data)

//获取评论信息
export const commentList = (data)=> get('/addons/shopro/goods_comment/index',data)

//新增评论
export const addComment = (data)=> post('/addons/shopro/goods_comment/add',data)

//定制商品
export const cuntomGoods = (data)=> post('/addons/shopro/goods/inquiry',data)

//获取同类商品
export const sameGoods = (data)=> post('/addons/shopro/goods/sameList',data)

//获取关键字
export const keyword = (data)=> post('/addons/shopro/goods/getKeywords',data)

//物流计算
export const logistics = (data)=> post('/api/express/index',data)

//创建订单
export const addOrder = (data)=> post('/addons/shopro/order/createOrder',data)

//获取订单列表
export const orderList = (data)=> get('/addons/shopro/order/getList',data)

//其他物流
export const otherShipping = (data)=> post('/addons/shopro/order/inquiry',data)
