<template>
    <div class="row">
        <div class="contetn_left">
            <div class="left_head"></div>
            <div class="left_foot"></div>
        </div>
        <div class="content_right">
            <el-row>
                <el-col :span="7">
                    <div class="col1">
                        <div class="col1-top" @click="handleJump('183')">
                            <div class="col1_top">
                                <img src="@/assets/images/monther.png" alt="">
                                <div class="mother">MOTHERS</div>
                                <div class="day">DAY</div>
                            </div>
                            <div class="col1_foot">VIEW MORE</div>
                        </div>
                        <div class="col1_bottom" @click="handleJump('187')">
                            <img src="@/assets/images/gifts.png" alt="">
                            <div class="gifts">VALENTINES'DAY <br />CRYSTAL GIFTS</div>
                            <div class="more">MORE</div>
                        </div>
                    </div>
                </el-col>
                <el-col class="scenery" :span="4" >
                    <img src="@/assets/images/scenery.png" @click="handleJump('184')"/>
                    <div class="father">FATHERS <br /><span>DAY</span> </div>
                    <div class="hot">Hot products zone</div>
                    <div class="viewMore">view more</div>
                </el-col>
                <el-col :span="9">
                    <div class="clo3">
                        <div class="clo3_top" @click="handleJump('185')">
                            <div class="top_left"></div>
                            <div class="top_right">
                                <div class="bir">Birthday <br /> Crystal<br /> Present</div>
                                <div class="view">VIEW MORE</div>
                            </div>
                        </div>
                        <div class="clo3_foot" @click="handleJump('189')">
                            <div class="christmas">Christmas & <br />New Year</div>
                            <div class="text">Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do</div>
                            <div class="viewMore">VIEW MORE</div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="clo4">
                        <div class="clo4_top">
                            <div class="top_text">SHOPPING <br /> PERSENTS ON</div>
                            <div class="top_view">VIEW MORE</div>
                        </div>
                        <div class="clo4_foot">
                            <div class="foot_box" @click="handleJump('186')">
                                <div class="box_img">
                                    <img src="@/assets/images/calendar.png" alt="">
                                </div>
                                <div class="boc_text">Anniversary <br />Crystal Crafts</div>
                            </div>
                            <div class="foot_box" @click="handleJump('190')">
                                <div class="box_img">
                                    <img src="@/assets/images/calendar.png" alt="">
                                </div>
                                <div class="boc_text">Wedding <br />Crystal Souvenirs</div>
                            </div>
                            <div class="foot_box" @click="handleJump('192')">
                                <div class="box_img">
                                    <img src="@/assets/images/calendar.png" alt="">
                                </div>
                                <div class="boc_text">Crystal For <br />Religion</div>
                            </div>
                            <div class="foot_box">
                                <div class="box_img">
                                    <img src="@/assets/images/calendar.png" alt="">
                                </div>
                                <div class="boc_text">Other <br />classifications</div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
        };
    },
    watch: {},
    computed: {},
    methods: {
      //分类查询
      handleJump(id){
        this.$router.push({
          path: '/product',
          query: {
            id: id
          }
        })
      }
    },
    created() { },
    mounted() { }
};
</script>
<style lang="less" scoped>
.row {
    width: 100%;
    height: 55vh;
    display: flex;
}

.contetn_left {
    width: 4%;
    height: 100%;
    padding-right: 10px;

    .left_head {
        width: 100%;
        height: 10%;
        background: #C70000;
    }

    .left_foot {
        width: 100%;
        height: 90%;
        background: #1B2E4E;
    }
}

.content_right {
    width: 96%;
    height: 100%;

    .el-row {
        width: 100%;
        height: 100%;

        .el-col {
            height: 100%;
        }

        .el-col:nth-child(1) {
            .col1 {
                width: 100%;
                height: 100%;
            }

            .col1 div:nth-child(1) {
                width: 100%;
                height: 70%;
                position: relative;



                .col1_top {
                    width: 100%;
                    height: 100%;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    div {
                        font-size: 22px;
                        font-family: Geometr415 Blk BT;
                        font-weight: 900;
                        color: #1B2E4E;
                        line-height: 29px;
                    }

                    .mother {
                        position: absolute;
                        top: 10%;
                        right: 10%;
                    }

                    .day {
                        position: absolute;
                        top: 20%;
                        right: 10%;
                        border-bottom: 3px solid #1B2E4E;
                        ;
                    }
                }

                .col1_foot {
                    font-size: 12px;
                    font-family: Acumin Variable Concept;
                    font-weight: normal;
                    color: #FFFFFF;
                    line-height: 10px;
                    position: absolute;
                    bottom: 20%;
                    right: 10%;
                    padding: 5px;
                    border: 1px solid #FFFFFF;
                }
            }

            .col1_bottom {
                width: 100%;
                height: 30%;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .gifts {
                    font-size: 13px;
                    font-family: Geometr415 Blk BT;
                    font-weight: 900;
                    color: #000000;
                    line-height: 20px;
                    position: absolute;
                    top: 10%;
                    left: 5%;
                }

                .more {
                    font-size: 8px !important;
                    font-family: Acumin Variable Concept;
                    font-weight: normal;
                    color: #000000;
                    line-height: 10pSx;
                    position: absolute;
                    top: 60%;
                    left: 5%;
                    border-bottom: 1px solid #000;
                }
            }
        }

        .el-col:nth-child(2) {
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            .father {
                font-size: 26px;
                font-family: Geometr415 Blk BT;
                font-weight: 900;
                color: #FFFFFF;
                line-height: 34px;
                position: absolute;
                top: 10%;
                right: 10%;
                text-align: center;

                span {
                    border-bottom: 2px solid #FFFFFF;
                    padding-bottom: 5px;
                }
            }

            .hot {
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-family: Arial;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 13px;
                position: absolute;
                left: 50%;
                top: 30%;
                transform: translate(-50%, -30%);
            }

            .viewMore {
                width: 80px;
                text-align: center;
                font-size: 15px;
                font-family: Acumin Variable Concept;
                font-weight: normal;
                color: #FFFFFF;
                line-height: 13px;
                position: absolute;
                left: 50%;
                top: 80%;
                transform: translate(-50%, -80%);
                padding: 10px;
                border: 1px solid #FFFFFF;
            }
        }

        .el-col:nth-child(3) {
            height: 100%;

            .clo3 {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                .clo3_top {
                    flex: 1;
                    width: 100%;
                    display: flex;

                    .top_left {
                        flex: 6;
                        background: url(@/assets/images/cake.png) no-repeat;
                        background-size: cover;
                    }

                    .top_right {
                        flex: 4;
                        background: #C70000;
                        position: relative;

                        .bir {
                            font-size: 20px;
                            font-family: Geometr415 Blk BT;
                            font-weight: 900;
                            color: #FFFFFF;
                            line-height: 25px;
                            position: absolute;
                            top: 20%;
                            left: 50%;
                            transform: translate(-50%, -20%)
                        }

                        .view {
                            width: 80px;
                            text-align: center;
                            font-size: 10px;
                            font-family: Acumin Variable Concept;
                            font-weight: normal;
                            color: #FFFFFF;
                            line-height: 9px;
                            position: absolute;
                            top: 80%;
                            left: 50%;
                            transform: translate(-50%, -80%);
                            border: 1px solid #FFFFFF;
                            padding: 10px;
                        }
                    }
                }

                .clo3_foot {
                    flex: 1;
                    background: url(@/assets/images/christmas.png) no-repeat;
                    background-size: 100% 100%;

                    .christmas {
                        font-size: 30px;
                        font-family: Geometr415 Blk BT;
                        font-weight: 900;
                        color: #FFFFFF;
                        line-height: 36px;
                        text-shadow: 0px 2px 5px rgba(46, 46, 46, 0.45);
                        padding: 20px 0 0 20px;
                    }

                    .text {
                        width: 150px;
                        word-wrap: break-word;
                        font-size: 8px;
                        font-family: Acumin Variable Concept;
                        font-weight: normal;
                        color: #FFFFFF;
                        line-height: 11px;
                        text-shadow: 0px 2px 5px rgba(46, 46, 46, 0.45);
                        padding: 3vw 0 0 2vw;
                    }

                    .viewMore {
                        font-size: 14px;
                        font-family: Acumin Variable Concept;
                        font-weight: normal;
                        color: #FFFFFF;
                        line-height: 16px;
                        padding-left: 2vw;
                        padding-top: 4%;
                    }
                }
            }
        }

        .el-col:nth-child(4) {
            height: 100%;

            .clo4 {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                .clo4_top {
                    flex: 3;
                    background: #000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;

                    .top_text {
                        font-size: 23px;
                        font-family: Geometr415 Blk BT;
                        font-weight: 900;
                        color: #C70000;
                        line-height: 30px;
                    }

                    .top_view {
                        font-size: 15px;
                        font-family: Acumin Variable Concept;
                        font-weight: normal;
                        color: #FFFFFF;
                        line-height: 22px;
                        padding-top: 10px;
                    }
                }

                .clo4_foot {
                    flex: 7;
                    display: flex;
                    flex-direction: column;

                    .foot_box {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #1B2E4E;
                        border: 1px dashed #FFFFFF;

                        .box_img {
                            width: 50px;
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            background: rgb(234, 216, 216);

                            img {
                                width: 60%;
                                height: 60%;
                            }
                        }

                        .boc_text {
                            font-size: 10px;
                            font-family: Arial;
                            font-weight: normal;
                            color: #FFFFFF;
                            line-height: 14px;
                            padding-left: 10px;
                        }
                    }

                    .foot_box:nth-child(1) {
                        background: #C70000;
                    }
                }
            }
        }
    }

}

.col1-top:hover,
.col1_bottom:hover,
.scenery:hover 
.top_left:hover,
.top_right:hover,
.clo3_foot:hover
.clo4_top:hover,
.foot_box:hover {
    animation-name: light;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes light {
    from {
        opacity: 1;
    }

    to {
        opacity: 0.2;
    }
}</style>