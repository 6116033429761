<template>
  <div class="text_container">
    <div class="text_title" v-html="title"></div>
    <div class="text_content" v-if="show">
      <span>
        {{ content }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props:{
    show:{
      type:Boolean,
      default:false
    },
    title:{
      type:String,
      default:"" 
    },
    content:{
      type:String,
      default:""
    }
  },
  methods: {},
  created() { },
  mounted() { }
}
</script>

<style lang=less scoped>
.text_container {
  width: 100%;
  height: 100%;
  text-align: center;
}

.text_title {
  font-size: 46px;
  font-family: Arial;
  font-weight: bold;
  color: #1B2E4E;
  line-height: 80px;
  display: flex;
  justify-content: center;
}

.text_content {
  
  font-size: 15px;
  font-family: Jost;
  font-weight: 100;
  color: rgba(94, 91, 91, 0.7);
  line-height: 30px;
  margin-bottom: 10px;
}
.text_content span{
  display: inline-block;
  width: 660px;
  word-break:break-word;
}
</style>