<template>
  <div id="app">
    <shopHeader v-show="$route.meta.showHead == 'shopHeader'" :currentId="$route.meta.currentId"></shopHeader>
    <websiteHeader v-show="$route.meta.showHead == 'websiteHeader'" :currentId="$route.meta.currentId"></websiteHeader>
    <router-view />
    <Footer v-show="$route.meta.showFoot"></Footer>
  </div>
</template>

<script>
import shopHeader from "@/components/shopHeader/index.vue"
import websiteHeader from "@/components/websiteHeader/index.vue"
import Footer from "@/components/footer";
export default {
  name: "App",
  components: {
    shopHeader,
    websiteHeader,
    Footer,
  },
  data() {
    return {

    }
  },
  methods: {
  },
  created() {

  },

  mounted() {
    
  }
}
</script>
<style lang="less">
// *{box-sizing: border-box;}
body{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  #app{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
