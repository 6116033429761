<template>
  <div class="home">
    <div class="home_top" @click="handleGo()">
      <div class="top_title">
        <span>PERSONALIZED</span>
        <span></span>
      </div>
      <div class="top_content">
        <div class="class_text">We shot a short film to make
          <br />
          a sentimental product
        </div>
        <div class="class_text active">Feelings </div>
        <div class="class_text">ln order to do a feeling of products <br />We made a short film.</div>
        <div class="class_text">To you on the road<br />
          And your passionate youth</div>
      </div>
    </div>
    <div class="home_content">
      <div class="content">
        <TitleText :title="titleContent" :content="content" :show="true"></TitleText>
      </div>
      <div class="home_clock content">
        <img src="@/assets/images/clock.png" alt="" srcset="">
      </div>
      <div class="home_imgs content">
        <div class="imgs_img" @click="handleJump('219')">
          <img class="img_active" src="@/assets/images/rusia.png" alt="" srcset="">
          <span class="img_text">From</span>
          <span class="img_country">Rusia Warehouse</span>
        </div>
        <div class="imgs_img" @click="handleJump('220')">
          <img src="@/assets/images/germany.png" alt="" srcset="">
          <span class="img_text">From</span>
          <span class="img_country">Germany Warehouse</span>
        </div>
        <div class="imgs_img" @click="handleJump('221')">
          <img src="@/assets/images/france.png" alt="" srcset="">
          <span class="img_text">From</span>
          <span class="img_country">France Warehouse</span>
        </div>
      </div>
      <div class="content">
        <TitleText style="margin-top:70px ;" :title="titleContent1" content="Do not miss new creations & discoveries."
          :show="true"></TitleText>
      </div>
      <div class="home_pics content">
        <div class="pics_pic">
          <img src="@/assets/images/arrivals.png" alt="">
          <div class="pics_title">New Arrivals</div>
          <div class="pics_content">
            <div>WHAT KING OF EXPERIENCE IS BURNING</div>
            <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Quis ipsum suspendisse</div>
          </div>
        </div>
        <div class="pics_pic" style="margin-right: 0;">
          <img src="@/assets/images/coming.png" alt="">
          <div class="pics_title">Coming Soon</div>
          <div class="pics_content">
            <div>WHAT KING OF EXPERIENCE IS BURNING</div>
            <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Quis ipsum suspendisse</div>
          </div>
        </div>
      </div>
      <div class="content" style="padding-top:20px ;">
        <TitleText :title="titleContent2"
          content="Who you are loving is a time to take good care them and make them happy." :show="true"></TitleText>
      </div>
      <div class="content">
        <Carousel :imgList="buyList"></Carousel>
      </div>
      <div class="content">
        <TitleText :title="titleContent3"
          content="Because mom is the first, dad is important, every festival or special time should be impressed by others, that’s all for HONOROFCRYSTAL has to do better and better."
          :show="true"></TitleText>
      </div>
      <div class="shopping">
        <div class="content row">
          <ComplexCard></ComplexCard>
        </div>
      </div>

      <div class="content" style="text-align: -webkit-center;margin-top:35px ;">
        <TitleText :title="titleContent4" :show="false"></TitleText>
        <div class="text_bottom"></div>
      </div>
      <div class="content">
        <Slideshow></Slideshow>
      </div>
      <!-- <div class="content">
          <TitleText :title="titleContent5" :content="content" :show="true"></TitleText>
        </div> -->

    </div>
    <div class="characters">
      <div class="contetn_foot content">
        <div class="content_foot_left">
          <img src="@/assets/images/logo.png" alt="">
          <div>HOT SELLIGNS</div>
          <img class="arrows" src="@/assets/images/arrows.png" alt="">
          <span style="font-size: 16px;line-height: 22px;">All the hot items<br />You may likes<br />Just make a
            click<br />To review the descriptions<br /></span>
        </div>
        <div class="content_foot_right">
          <Lunbo :imgList="hotList"></Lunbo>
        </div>

      </div>
    </div>
  </div>
</template>
  
<script>
import TitleText from './components/titleText/index.vue'
import Carousel from './components/carousel/index.vue'
import Lunbo from './components/carousel1/index.vue'
import Slideshow from './components/slideshow/index.vue'
import ComplexCard from './components/complexCard/index.vue'
import r from '@/api/index'
export default {
  name: 'HomeView',
  components: {
    TitleText,
    Carousel,
    ComplexCard,
    Slideshow,
    Lunbo
  },
  data() {
    return {
      titleContent: "Fast Shipping Solutions & Facility",
      content: "Because of last 3 years’ COVI-19, most of cargo companies cannot do anything on the services for the global business, as the brand development we HONOROFCRYSTAL made a challenge for ourselves, to set few overseas’ warehouses to meet our valued clients’ requests.",
      titleContent1: '<div style="font-size:46px;">Catch The Trends With </div>' + '<div style="color:red; margin-left:12px; font-size:46px;">Honorofcrystal</div>',
      titleContent2: " Crystal Gifts & Crafts To Buying For",
      titleContent3: "Shopping Presents on",
      titleContent4: "Honorofcrystal Offers More",
      titleContent5: "HOT SELLIGNS",
      hotList: [],
      buyList: JSON.parse(localStorage.getItem("category"))
    }
  },
  created() { },
  methods: {
    handleGo() {
      // this.$router.push("/product")
    },
    //获取热销商品
    getHot() {
      this.api.goods.hotGoodsList().then(res => {
        this.hotList = res.data
      })
    },
    //通过发货仓库帅选
    handleJump(id){
      this.$router.push({
        path:'/product',
        query:{
          warehouse:id
        }
      })
    }
  },
  mounted() {
    this.getHot()
  }
}
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  position: relative;
}

.home_top {
  width: 100%;
  height: 681px;
  background: url("@/assets/images/home_bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.top_title {
  position: absolute;
  right: 13vw;
  top: 21%;
  font-size: 5vw;
  font-family: Jost;
  font-weight: 800;
  color: #FFFFFF;
  line-height: 82px;
}

.top_title span:nth-child(2) {
  display: block;
  position: absolute;
  right: -3%;
  top: 75%;
  width: 10px;
  height: 10px;
  background: #ffffff;
}

.top_content {
  position: absolute;
  top: 40%;
  right: 25%;
}

.class_text {
  font-size: 18px;
  font-family: Arial;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 33px;
}

.active {
  font-size: 25px;
  font-family: Arial;
  font-weight: 400;
  color: #C70000;
}

.home_content {
  position: relative;
  height: 100%;
  width: 100%;
  // width: 70%;
  // margin: 0 auto;
}

.home_clock {
  text-align: center;

  img {
    text-align: center;
    width: 30px;
    height: 30px;
  }
}

.home_imgs,
.home_pics {
  display: flex;
  justify-content: space-between;
}

.imgs_img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 330px;
    height: 250px;
  }

  .img_text {
    font-size: 20px;
    font-family: Arial;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }

  .img_country {
    font-size: 21px;
    font-family: Arial;
    font-weight: bold;
    color: #333333;
    line-height: 35px;
  }
}

//动画
img:hover {
  animation-name: light;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes light {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.2;
  }
}



.pics_pic {
  width: calc((100% - 30px) / 2);
  height: max-content;
  margin-right: 30px;
}

.pics_pic {
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .pics_title {
    text-align: center;
    width: 100px;
    height: 30px;
    background: #C70000;
    border-radius: 2px;
    font-size: 12px;
    font-family: Jost;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 30px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 85px;
  }

  .pics_content {
    text-align: center;
    padding: 20px 20px 0 20px;
    height: 80px;
    background: #F5F5F5;

    div:nth-child(1) {
      font-size: 17px;
      font-family: Arial;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }

    div:nth-child(2) {
      font-size: 13px;
      font-family: Arial;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
    }
  }
}
.shopping{
  width: 100%;
  height: max-content;
  background:rgba(225, 225, 225, .49);
  padding: 40px;
  box-sizing: border-box;
}
.text_bottom {
  width: 10%;
  border-bottom: 1px solid #C70000;
  border-bottom-width: 5px;
}

.characters {
  width: 100%;
  height: 40vh;
  background: #1B2E4E;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

}

.contetn_foot {
  height: 85%;
  background: #FFFFFF;
  display: flex;

  .content_foot_left {
    flex: 4;

    img:nth-child(1) {
      width: 10vw;
      padding: 20px 0 0 40px;
    }

    div {
      font-size: 2.5vw;
      font-family: Arial;
      font-weight: bold;
      color: #1B2E4E;
      line-height: 50px;
      padding-left: 40px;
    }

    .arrows {
      width: 6vw;
      padding: 25px 0 0 40px;
    }

    span {
      display: inline-block;
      width: 40%;
      word-break: break-all;
      font-size: 11px;
      font-family: Arial;
      font-weight: 400;
      color: #1B2E4E;
      line-height: 15px;
      padding-left: 10px;
    }
  }

  .content_foot_right {
    flex: 6;
  }
}
.content{
  width: 70%;
  margin: 0 auto;
}
</style>
  